.Page_container {
  width: 100%;
}

.Page_children {
  max-width: 1190px;
  margin: 0 auto;
  position: relative;
  min-height: calc(100vh - 417px);
}

.Page_compact .Page_children {
  min-height: calc(100vh - 159px);
}
